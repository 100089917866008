@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles */
#scroll-1, #scroll-2, #scroll-3, #scroll-4, #scroll-5 {
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

#scroll-1::-webkit-scrollbar, #scroll-2::-webkit-scrollbar,
#scroll-3::-webkit-scrollbar, #scroll-4::-webkit-scrollbar,
#scroll-5::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome/Safari */
}